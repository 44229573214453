import React from 'react';
import Layout from '../../../components/Layout';
import Merchants_Navbar from '../../../components/Merchants_Navbar';

function Index(props) {
    return (
        <div>
            <Layout location={'Merchants'} title={'POS Systems'}>
            <Merchants_Navbar location={'pos-solutions'}/>
            <section className="cw-bg-bluegradient text-center py-10 px-5">
                <div className="text-center w-100">
                    <h1 className="text-5xl text-white font-bold pb-2">POS Systems</h1>
                </div>
            </section>
            <section className='py-20 bg-gray-100'>
                <div className="max-w-3xl mx-auto px-5 text-center">
                    <div className='mb-7 space-y-5'>
                        <h2 className="text-3xl cw-text-lightblue font-bold mb-3">POS Systems</h2>
                        <p className="font-serif text-xl leading-8 text-left">
                        A retail point of sale system typically includes a cash register (which in recent times comprises a computer, monitor, cash drawer, 
                        receipt printer, customer display and a barcode scanner) and the majority of retail POS systems also include a debit/credit card reader.
                        </p>
                        <p className="font-serif text-xl leading-8 text-left">
                            Below you will find a list of the POS Systems that we currently process through for our merchants.
                        </p>
                        <p className="font-serif text-xl leading-8 text-left">
                            Don't See your current POS System on this list? Let us know and we will work with your provider to 
                            make sure that we can accommodate your POS!
                        </p>
                    </div>
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479195/cwa/assets/images/Merchants/POS%20Systems/Clean-Aldelo-Logo_jgyhwa.png"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">ALDELO</h2>
                            <p className="py-4 font-serif">
                                When Aldelo was founded, we had only one mission in mind; it is the mission to develop simple to use, feature rich, reliable 
                                and cost effective software solutions for the hospitality, retail and payment processing industries.
                            </p>
                            <p className="py-4 font-serif">
                                Throughout the years, we have kept that mission in mind for our growth strategies and products. This unyielding focus to our 
                                original mission has helped us achieve over 80,000 installations and over 500 channel partners around the world.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">ALOHA</h2>
                            <p className="py-4 font-serif">
                                With NCR Aloha, the wait is over. Our multi-functional restaurant POS solution gives operators all the tools they need to boost sales and 
                                increase the pace of service. Staff can use the POS solution to enter orders and payments, streamline food preparation and delivery, and 
                                improve operations. NCR Aloha POS is ideal for quick service, fast casual, casual dining and fine dining restaurants and can be used on 
                                fixed POS terminals, built-for-purpose handheld devices and consumer mobile devices
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479281/cwa/assets/images/Merchants/POS%20Systems/Aloha-Logo_xd4xew.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479343/cwa/assets/images/Merchants/POS%20Systems/breadcrumb-long_cbvgug.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">BREADCRUMB BY GROUPON</h2>
                            <p className="py-4 font-serif">
                                Breadcrumb by Groupon is a suite of iPad point of sale products and payments solutions that help businesses operate faster, know their 
                                customers better and maximize revenue. All our products come with a low price guarantee on credit card transaction fees, free installation 
                                assistance, and 24/7 phone technical support.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">DINERWARE</h2>
                            <p className="py-4 font-serif">
                                Founded in 2000, Seattle-based Dinerware provides restaurant point of sale software solutions to the hospitality industry. Dinerware can 
                                be found in upscale restaurants, cafes, nightclubs, bar and grills, fast casual restaurants, counter service establishments, quick serve 
                                restaurants, hotels, casinos, cafeterias, golf courses, and wineries.
                            </p>
                            <p className="py-4 font-serif">
                                Thousands of restaurants have selected Dinerware software based on word of mouth recommendations from our existing customers based on its 
                                ease of use, reliability, and flexibility. Dinerware is an RSPA-certified vendor, and is sold and supported through an authorized dealer 
                                network across North America, the United Kingdom, and other countries around the world served by our partners in the Middle East and Southeast Asia.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479519/cwa/assets/images/Merchants/POS%20Systems/dinerware_corporate_logo2_qqahej.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479639/cwa/assets/images/Merchants/POS%20Systems/Epicor_Logo1_utxlmg.png"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">EPICORE – ACTIVANT</h2>
                            <p className="py-4 font-serif">
                                This is a web-based tool that offers real-time e-Business applications designed to allow you and your customers to access and 
                                interact with the Activant Silk Business Management System through the Internet from anywhere, anytime.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">EVENTBRITE</h2>
                            <p className="py-4 font-serif">
                                Eventbrite brings people together through live experiences. Discover events that match your passions, or create your own with 
                                online ticketing tools. Accept credit and debit cards with Eventbrite's secure payment processor, PayPal, and more. Track sales 
                                and refunds all in one place.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479753/cwa/assets/images/Merchants/POS%20Systems/eventbrite-logo_sveyou.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479811/cwa/assets/images/Merchants/POS%20Systems/ezfacility_lvu2hf.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">EZ FACILITY</h2>
                            <p className="py-4 font-serif">
                                EZFacility, a division of Jonas Software, is a leading provider of web based software solutions for sports and fitness businesses 
                                around the world. With over a decade of unparalleled expertise in sports and fitness facility management, EZFacility provides its 
                                thousands of users with cutting-edge technology to streamline the daily operations and management of their facilities.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">FOCUS POS</h2>
                            <p className="py-4 font-serif">
                                For more than 20 years, Focus POS Systems has provided restaurant management software to hospitality establishments. Our team 
                                has installed innovative restaurant point of sale solutions at more than 10,000 unique locations worldwide. Since 1990, we have 
                                aimed to give restaurants a competitive edge by providing simple, yet powerful point of sale solutions. Our reliable products combined 
                                with our tried-and-true methodology allow restaurant managers to promote efficient operations, increase productivity, improve 
                                profitability and deliver business value. Focus specializes in providing restaurant point of sale solutions for table and counter 
                                service, take-out, delivery, bars, nightclubs and cafes. Many well-known hospitality organizations rely on Focus POS to streamline 
                                their daily operations, including Smoothie King, Coldstone Creamery, Popeye’s, Arby’s and KFC.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479875/cwa/assets/images/Merchants/POS%20Systems/Focus-POS-2_ok11g1.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479944/cwa/assets/images/Merchants/POS%20Systems/freedom-data-systems_daujyx.gif"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">FREEDOM DATA SYSTEMS</h2>
                            <p className="py-4 font-serif">
                                Freedom Data Systems, Inc. is dedicated to providing accessible, cost-effective and efficient automated Pharmacy Management Systems for 
                                the independent pharmacy and small to medium-sized chain pharmacy. Freedom Data Systems continues to build an integrated approach to retail 
                                pharmacy automation. Through innovative system development and a commitment to building strategic business partnerships, we provide 
                                value-added benefits to our customers that set us apart in the industry.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">FUTURE POS</h2>
                            <p className="py-4 font-serif">
                                Future POS develops award-winning point-of-sale software for restaurants, including fine dining, quick serve, retail, and specialty 
                                applications. Our tools are “mission critical,” stress tested well beyond what most other point-of-sale packages can handle, and 
                                Future POS can run any size operation from a single terminal installation to a 77 terminal stadium.
                            </p>
                            <p className="py-4 font-serif">
                                Founded in 1998, Future POS celebrated its 17 year anniversary last year, with sales up over 22%. The company has come a long way since 
                                its humble beginnings and can be seen regularly on SPIKE TV’s “Bar Rescue™.” You’ll also find Future POS software at some of the finest 
                                restaurants around the world.
                            </p>
                            <p className="py-4 font-serif">
                                Future POS is committed to providing our restaurants with the most powerful, robust and user-friendly software on the market. We pride 
                                ourselves on leading the industry in innovation, constantly integrating the latest technologies and offering the most advanced point-of-sale 
                                product. We strive to bring these solutions to our customers at a price that can’t be matched in value.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650479982/cwa/assets/images/Merchants/POS%20Systems/FUTURE-POS_tzokil.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650480220/cwa/assets/images/Merchants/POS%20Systems/lavu-logo_xllya8.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">FREEDOM DATA SYSTEMS</h2>
                            <p className="py-4 font-serif">
                                Lavu Inc. is a software + technology company based in Albuquerque, NM. and are the makers of the first full-featured iPad POS for restaurants 
                                available on the Apple App Store in 2010.
                            </p>
                            <p className="py-4 font-serif">
                                Our mission: “Simplify business + prepare entrepreneurs for success.”
                            </p>
                            <p className="py-4 font-serif">
                                Used in over 80 countries on six continents, our iPad POS is a modern solution developed specifically for restaurants, bars & nightclubs, quick 
                                serve businesses, and other hospitality industries. We are leading the shift toward mobile solutions for the service industry with a strong 
                                worldwide reseller network and thousands of customers.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">LIMO WHIZ</h2>
                            <p className="py-4 font-serif">
                                LIMO WHIZ was established primarily to serve the software needs of Limousine businesses, provide web design, consulting and networking services 
                                for other types of businesses.
                            </p>
                            <p className="py-4 font-serif">
                                We are committed to the development of LimoWiz and other related software solutions that will be tightly integrated. New features and enhancements 
                                are developed continuously to increase productivity, accuracy in dispatching, billing, A/R, payroll, reporting, email and credit card authorization.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650480315/cwa/assets/images/Merchants/POS%20Systems/limowizlogo2011_zb4t8f.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 ">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650480604/cwa/assets/images/Merchants/POS%20Systems/micros-thumb-341x250_rzrcr4.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">MICROS</h2>
                            <p className="py-4 font-serif">
                                MICROS provides integrated, end-to-end solutions to the hospitality and retail industries. All our solutions, including integrated front office, 
                                back office, central and web-based solutions, are designed to optimize and streamline workflows, improve customer service, and increase the 
                                bottom line. We invest heavily in the development of new interfaces to enable direct interaction and communication with the guest through 
                                several channels, such as apps, internet, IPTV, call centers and kiosks.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">ORDYX</h2>
                            <p className="py-4 font-serif">
                                Ordyx came about based on the idea that technology should be available to all. Whether it is the chain with hundreds of establishments or the 
                                mom and pop corner restaurant. They all need to track orders, take payments, recognize what items sell and who is buying them. In essence, 
                                they all need tools to help them understand their business, their services and products, and more importantly their customers.
                            </p>
                            <p className="py-4 font-serif">
                                Ordyx is best described as an innovator instead of a follower, a partner to its customers rather than just a vendor. By providing no 
                                contracts from day one, Ordyx has created a collaborative relationship with customers. Ordyx succeeds only as a result of helping its 
                                customers achieve their goals by listening to customer suggestions, foreseeing customer needs, and, in turn, providing innovative technology.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650480513/cwa/assets/images/Merchants/POS%20Systems/ordyx_Logo_zizwz3.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650480696/cwa/assets/images/Merchants/POS%20Systems/Reflection-POS-Logo-Print-300x300_ygyqxw.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">NCC REFLECTION POS</h2>
                            <p className="py-4 font-serif">
                                Reflection POS® can be used in a variety of markets from fine dining to pizzerias, bars to country clubs; all with the goal of 
                                providing you a reliable and efficient way to improve your bottom line. Reflection POS® is built on solid-state technology and 
                                has a reliable embedded operation system. It is the most sophisticated POS network on the market and has been validated by an 
                                Independent Qualified Security Assessor to meet or exceed PCI card payment processing requirements.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">NETSUITE</h2>
                            <p className="py-4 font-serif">
                                NetSuite Inc. is an American software company based in San Mateo, California that sells a group of software services used to 
                                manage a business’s operations and customer relations. Customers access these services over the internet paying a periodic 
                                subscription fee. NetSuite’s services are primarily aimed at medium to enterprise-sized businesses.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650480755/cwa/assets/images/Merchants/POS%20Systems/netsuite-logo-600x180_amjvty.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650480857/cwa/assets/images/Merchants/POS%20Systems/PAYSIMPLE_mc54sm.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">PAYSIMPLE</h2>
                            <p className="py-4 font-serif">
                                PaySimple offers products directly to small business merchants, and also partners with organizations to provide the technology 
                                and support for white-labeled products.
                            </p>
                            <p className="py-4 font-serif">
                                Since its inception, PaySimple has been completely focused on the success of small businesses across the service sectors. Its technology 
                                provides small businesses with the three fundamental abilities inside one seamless technology that enable them to run their businesses 
                                better—accept payments, manage customers, and automate billing. PaySimple has made big-business efficiencies available to small businesses 
                                at a fraction of the cost.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">PC AMERICA</h2>
                            <p className="py-4 font-serif">
                                Founded in 1985, pcAmerica has been providing computerized point of sale software solutions to the retail, restaurant, and 
                                hospitality industries for over 20 years. With over 50,000 users worldwide, our point of sale software solutions are used by 
                                both chains as well as single location independent operators.
                            </p>
                            <p className="py-4 font-serif">
                                Our lengthy track record as a proven point of sale solutions provider is driven by continuous product innovation, uncompromised 
                                customer service, and simplicity of use. Some notable pcAmerica users include Flowers Foods, Goodwill Stores, Baskin-Robbins, 
                                Tasti D-Lite and various United States Embassies and Consulates. Headquartered in Pearl River, New York, pcAmerica is a division 
                                of Automation Inc, a privately held corporation under the leadership of David Gosman, CEO, and Richard Rotbard, President.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650480898/cwa/assets/images/Merchants/POS%20Systems/pc-america_vjoxq5.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650481075/cwa/assets/images/Merchants/POS%20Systems/pixelpoint_lg2vxg.png"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">PC AMERICA</h2>
                            <p className="py-4 font-serif">
                                Expect to improve your guest experience and your bottom line with PixelPoint POS software, a solution designed to be amazingly 
                                easy to use, brilliantly flexible and rock solid reliable. Intuitive and concise screen layouts allow staff to enter orders with 
                                maximum efficiency so they have more time to focus on delivering an outstanding guest experience that will create loyal guests. 
                                Powerful BackOffice features let managers track sales, manage schedules and control inventory costs.
                            </p>
                            <p className="py-4 font-serif">
                                PixelPoint POS can be easily configured to work in any restaurant environment from fine dining to fast causal and adapts as your needs 
                                change. Designed to work in harmony with any hardware platform and built using an extensible software architecture ensures all PixelPoint 
                                modules work together seamlessly, eliminating costly integration and support fees.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">RADIENT SYSTEMS</h2>
                            <p className="py-4 font-serif">
                                Radiant is a leading global Information Technology company providing Solutions, Services and Products to a broad range of industry verticals. 
                                Our clients include over 50 of the Fortune 500 global corporations. Radiant has sustained an impressive growth rate in both capability and 
                                profitability, and is now positioned to be one of the major contributors in various sectors such as Telecom, Pharma & Life Sciences, Transportation, 
                                E-Governance, Financial & Insurance, Information Technology, Manufacturing, Retail, Utilities and others.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650481155/cwa/assets/images/Merchants/POS%20Systems/radiant_systems_blue_h500mw.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650481198/cwa/assets/images/Merchants/POS%20Systems/RESTAURANT-MANAGER-POS_dipzja.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">RESTAURANT MANAGER POS</h2>
                            <p className="py-4 font-serif">
                                Restaurant Manager offers complete restaurant POS systems that can be easily tailored for use in any sort of food service establishment, 
                                from fine dining and table service restaurants to quick service (QSRs), pizza delivery and take-out establishments, as well as bars and clubs
                            </p>
                            <p className="py-4 font-serif">
                                Designed as a turnkey solution that can grow with your business, Restaurant Manager POS systems offer everything you need to effectively 
                                manage your operations.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">RETAIL PRO</h2>
                            <p className="py-4 font-serif">
                                Retail Pro International, LLC, is a global leader in Point of Sale, Store Operations, and Back-Office software applications for 
                                the specialty retail industry. For over 25 years, we have economized retail business processes to give our customers more time 
                                to focus on what really matters – building relationships with patrons, employees, vendors, and trends.
                            </p>
                            <p className="py-4 font-serif">
                                Our uniquely flexible, extensible, and scalable retail management solutions empower you to retail your way. We provide fully integrated 
                                front and back-office functionality for your stores, warehouses, and headquarters into a single software solution. And Retail Pro is 
                                the trusted solution for retailers of all sizes – from owner-operated brick and mortar specialty stores up to large corporate-run retail 
                                chains, and nearly all flavors of specialty retail in between. Whether you’re an entrepreneur or a CTO of an already mature pan-global 
                                business, Retail Pro is always the right size to fit your business at any stage of growth.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650481324/cwa/assets/images/Merchants/POS%20Systems/Retail-Pro-Logo_serious-software_hhgqu4.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650481450/cwa/assets/images/Merchants/POS%20Systems/Revel_iPad_POS_491629_i0_hlh5yi.png"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">REVEL</h2>
                            <p className="py-4 font-serif">
                                Revel Systems is an award-winning iPad Point of Sale Solution for single location and multi-location businesses. The company 
                                was founded in 2010 in San Francisco, with the goal of changing the Point of Sale market. Founders Lisa Falzone and Chris Ciabarra 
                                developed a quick, intuitive and secure iOS-based Point of Sale system by combining cloud-based technology and the mobility of the iPad.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5 bg-gray-100">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">SHOPKEEP</h2>
                            <p className="py-4 font-serif">
                                ShopKeep is a cloud-based iPad point of sale (POS) system headquartered in New York, NY. Founded in 2008, its POS system is used 
                                by circa 15,000 small businesses[1] in the United States and Canada, most of which are retail shops, coffee shops, restaurants, 
                                and bars. The system allows merchants to ring up sales, print or email receipts, pop a cash drawer, accept credit cards and print 
                                remotely to the kitchen right from the iPad. The robust web-based BackOffice allows inventory, employee, and customer management, 
                                and robust analytics and reporting. The smartphone dashboard app allows merchants to view real-time store sales remotely.
                            </p>
                            <p className="py-4 font-serif">
                                ShopKeep accepts mobile payments via a partnership with PayPal[2] and another partnership with LevelUp.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img alt='' style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650481496/cwa/assets/images/Merchants/POS%20Systems/MM09126LOGO-b_xueju7.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            {/* <section className="cw-bg-bluegradient">
                <div className="px-5 text-center lg:text-left flex lg:justify-between py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif max-w-xl leading-8">Ready to switch to CWA Acquiring? Contact us at the number below OR Request a Quote</h3>
                    <button className="transition ease-in-out delay-150 border-2 border-white p-2 text-white hover:bg-white hover:cw-text-lightblue font-semibold text-lg">Request a Quote</button>
                </div>
            </section> */}
            </Layout>
        </div>
    );
}

export default Index;